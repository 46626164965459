import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import useGameStore from "./GameStore";
import { generateId } from "../../../global/lib/utils/generateId";

const usePersistentStore = createWithEqualityFn(
  persist(
    (set, get) => ({
      browserId: generateId(),

      picks: {},
      persistPick: (overallPick, player) => {
        set((prevState) => {
          console.log("Overall pick", overallPick);
          console.log("Persist", player?.id);
          const updatedPicks = Object.assign({}, prevState.picks);
          updatedPicks[overallPick] = player?.id;

          return {
            picks: updatedPicks,
            currentPick: Object.keys(updatedPicks).length + 1,
          };
        });
      },
      resetPicks: async () => {
        await useGameStore
          .getState()
          .setOrderByTeamId(useGameStore.getState().initialOrderByTeamId);

        setTimeout(() => {
          useGameStore.getState().generateOrderByTeamId();
        }, 400);

        set(() => ({
          picks: {},
          order: {},
          currentPick: 1,
        }));
      },

      order: {},
      persistOrder: (order) => {
        set({ order: order });
      },

      currentPick: 1,
      setCurrentPick: (pick) => {
        set({ currentPick: pick });
      },

      instructionsDismissed: false,
      saveInstructionsDismissed: (isDismissed) =>
        set(() => ({
          instructionsDismissed: isDismissed,
        })),
    }),
    {
      name: "phantom",
    }
  ),
  Object.is
);

export default usePersistentStore;
