import usePersistentStore from "../lib/store/PersistentStore";
import React, { useState } from "react";
import useGameStore from "../lib/store/GameStore";
import { hasHeadshot } from "../lib/utils/constants";
import { getTeamByAbbr } from "../lib/utils/helpers";

export function SearchRow({ overallPick, player, closeModal }) {
  const [completedPicks, persistPick] = usePersistentStore((state) => [
    state.picks,
    state.persistPick,
  ]);
  const takenIds = Object.values(completedPicks);
  const [selectedPlayerId, setSelectedPlayerId] = useState(
    completedPicks[overallPick],
  );

  // const selectedPlayerId = picks[order.overallPick];
  const isFinalPick = overallPick % 16 === 0;

  const [
    orderByTeamId,
    generateOrderByTeamId,
    setIsPreselectionOpen,
    setPreselectionPick,
    setIsShareOpen,
    setShareGroup,
    setShareJpegData,
  ] = useGameStore((state) => [
    state.orderByTeamId,
    state.generateOrderByTeamId,
    state.setIsPreselectionOpen,
    state.setPreselectionPick,
    state.setIsShareOpen,
    state.setShareGroup,
    state.setShareJpegData,
  ]);

  const teamAbbr = orderByTeamId[overallPick - 1];
  const team = getTeamByAbbr(teamAbbr);

  function onClick() {
    if (player.id === selectedPlayerId) {
      // Undo pick

      setTimeout(() => {
        persistPick(overallPick, null);
        closeModal();
      }, 400);
    } else {
      setSelectedPlayerId(player.id);

      setTimeout(async () => {
        await persistPick(overallPick, player);
        generateOrderByTeamId();

        closeModal();

        if (
          !isFinalPick &&
          player.preselectionTeam &&
          player.preselectionTeam.id !== team.id
        ) {
          setPreselectionPick(player.preselectionTeam.abbr);
          setIsPreselectionOpen(true);
        }
      }, 200);

      if (isFinalPick) {
        setShareGroup(Math.round(overallPick / 16));
        setShareJpegData(null);

        setTimeout(() => {
          setIsShareOpen(true);
        }, 200);
      }
    }
  }

  return (
    <button onClick={onClick} className="flex w-full items-center ">
      <div
        className={`w-full bg-white ${
          takenIds.includes(player.id) ? "opacity-100" : ""
        } ${
          selectedPlayerId === player.id
            ? "outline outline-2 outline-lime-500"
            : "hover:outline hover:outline-2 hover:outline-slate-400"
        }`}
      >
        <div className="flex items-center py-3">
          <div className="h-18 relative -ml-6 mr-2 flex w-28 flex-none items-center justify-center">
            <div className="absolute h-20 w-28">
              <div className="absolute -top-1 flex h-full w-full items-start justify-center overflow-hidden">
                {player &&
                  (hasHeadshot.includes(player.id) ? (
                    <img
                      src={`/build/images/sport/juniorplayers/${player.id}.png`}
                      alt={`${player.name} headshot`}
                      className="absolute -right-2 -top-4"
                    />
                  ) : (
                    <img
                      src={`/build/images/sport/juniorplayers/silhouette.png`}
                      alt={`${player.name} headshot`}
                      className="absolute -right-2 -top-4"
                    />
                  ))}
              </div>
            </div>
          </div>
          <div id="name" className="flex flex-none items-center">
            <div className="flex flex-col space-y-2">
              <div className="text-ellipsis text-left font-oswald text-2xl uppercase leading-none tracking-tight text-sky-950 antialiased">
                <span className="font-medium">{player.lastName}</span>
                <span className="font-thin">, {player.firstName}</span>
              </div>
              <div className="text-ellipsis text-left font-oswald text-md leading-none  text-sky-950 antialiased">
                {player.originatedFrom} • {player.height}cm/{player.weight}kg •{" "}
                {player.position}
              </div>
              <div className="text-ellipsis text-left font-oswald text-md leading-none text-sky-950 antialiased">
                Proj: {player.draftRange}
              </div>
            </div>
          </div>
          <div className="grow"></div>
        </div>
      </div>
    </button>
  );
}
