export const hasHeadshot = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 71, 77, 81, 82,
];

export const draftValues = [
  [1, 3000],
  [2, 2517],
  [3, 2234],
  [4, 2034],
  [5, 1878],
  [6, 1751],
  [7, 1644],
  [8, 1551],
  [9, 1469],
  [10, 1395],
  [11, 1329],
  [12, 1268],
  [13, 1212],
  [14, 1161],
  [15, 1112],
  [16, 1067],
  [17, 1025],
  [18, 985],
  [19, 948],
  [20, 912],
  [21, 878],
  [22, 845],
  [23, 815],
  [24, 785],
  [25, 756],
  [26, 729],
  [27, 703],
  [28, 677],
  [29, 653],
  [30, 629],
  [31, 606],
  [32, 584],
  [33, 563],
  [34, 542],
  [35, 522],
  [36, 502],
  [37, 483],
  [38, 465],
  [39, 446],
  [40, 429],
  [41, 412],
  [42, 395],
  [43, 378],
  [44, 362],
  [45, 347],
  [46, 331],
  [47, 316],
  [48, 302],
  [49, 287],
  [50, 273],
  [51, 259],
  [52, 246],
  [53, 233],
  [54, 220],
  [55, 207],
  [56, 194],
  [57, 182],
  [58, 170],
  [59, 158],
  [60, 146],
  [61, 135],
  [62, 123],
  [63, 112],
  [64, 101],
  [65, 90],
  [66, 80],
  [67, 69],
  [68, 59],
  [69, 49],
  [70, 39],
  [71, 29],
  [72, 19],
  [73, 9],
  [74, 0],
];
