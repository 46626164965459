import React, { useState } from "react";
import { BaseDialog } from "../../../global/components/BaseDialog";

function HowToScoreDialog() {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <button
        className="flex items-center justify-center rounded-full px-4 py-2 font-medium text-white/80 hover:bg-white/20 hover:text-white"
        onClick={handleClick}
      >
        Scoring System
      </button>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={"How to Score"}
        modalBody={<HowToPlayBody />}
        size={"md"}
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 px-2 pt-2 text-md sm:px-4">
      <div className="mb-4">
        Earn points based on how close you were to predicting each player's
        actual draft position.
      </div>
      <div className="mb-6 space-y-1 font-medium">
        <div className="flex items-center justify-between border-b border-dashed py-1">
          <div>Exact Pick</div>
          <div>+100pts</div>
        </div>
        <div className="flex items-center justify-between border-b border-dashed py-1">
          <div>One Pick Away</div>
          <div>+50pts</div>
        </div>
        <div className="flex items-center justify-between border-b border-dashed py-1">
          <div>Two Picks Away</div>
          <div>+25pts</div>
        </div>
        <div className="flex items-center justify-between border-b border-dashed py-1">
          <div>Three Picks Away</div>
          <div>+10pts</div>
        </div>
        <div className="flex items-center justify-between border-b border-dashed py-1">
          <div>Four Or More Away / Undrafted</div>
          <div>0pts</div>
        </div>
      </div>
      <div className="my-4">
        <span className="font-bold">Example:</span> If you predict Zane Duursma
        to be selected 2nd, but he is actually selected 4th, you will earn
        +25pts.
      </div>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default HowToScoreDialog;
