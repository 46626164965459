import { createWithEqualityFn } from "zustand/traditional";
import { draftValues } from "../utils/constants";
import usePersistentStore from "./PersistentStore";

const useGameStore = createWithEqualityFn(
  (set, get) => ({
    players: null,
    setPlayers: (players) => {
      set({ players: players });
    },

    pickResults: null,
    setPickResults: (picks) => {
      set({ pickResults: picks });
    },

    lockState: "PRE",
    setLockState: (lockState) => {
      set({ lockState: lockState });
    },

    initialOrderByTeamId: null,
    setInitialOrderByTeamId: (order) => {
      set({ initialOrderByTeamId: order });
    },

    orderByTeamId: null,
    setOrderByTeamId: async (order) => {
      set({ orderByTeamId: order });
    },

    generateOrderByTeamId: () => {
      console.log("Generating order");
      // Order starts as provisional order
      let currentOrder = get().initialOrderByTeamId;
      //      console.log("Current order", currentOrder);

      const persistedPicks = usePersistentStore.getState().picks;

      const completedPicks = Object.values(persistedPicks);
      console.log("Found", completedPicks.length, "completed picks");

      // Replay each pick and update as needed
      for (let pickNum = 1; pickNum <= completedPicks.length; pickNum++) {
        const pick = completedPicks[pickNum - 1];
        const player = get().players.find((player) => player.id === pick);

        console.log(`Pick ${pickNum}: User took ${player.name}`);
        const picksToRemove = [];

        if (
          player.preselectionTeam &&
          player.preselectionTeam.abbr !== currentOrder[pickNum - 1]
        ) {
          console.log(`${player.name} is a preselection`);
          console.log(`Deducting points`);
          const pickValue = draftValues[pickNum - 1][1];
          const discount = pickNum <= 19 ? 0.8 : 1;
          const pickCost = Math.round(pickValue * discount);
          console.log(pickValue, "down to", pickCost);

          // Start updated order as current order up to this pick, plus preselection Team
          const updatedOrder = [
            ...currentOrder.slice(0, pickNum - 1),
            player.preselectionTeam.abbr,
          ];

          let clubPoints = 0;
          let pointsRemainder = clubPoints - pickCost;

          // Go through remaining picks until we have enough value
          for (
            let futurePickNum = pickNum;
            futurePickNum <= currentOrder.length;
            futurePickNum++
          ) {
            const futurePickTeam = currentOrder[futurePickNum - 1];
            // console.log(
            //   `Looking at pick ${futurePickNum}, currently owned by ${futurePickTeam}`
            // );

            if (
              futurePickNum <= 73 /* last pick of value */ &&
              futurePickTeam === player.preselectionTeam.abbr &&
              pointsRemainder < 0
            ) {
              console.log(
                `Removing pick ${futurePickNum}, currently owned by ${futurePickTeam}`
              );

              picksToRemove.push(futurePickNum);
              const pickPoints = draftValues[futurePickNum - 1][1];
              // console.log("Adding", pickPoints, "points");
              clubPoints += pickPoints;
              pointsRemainder = clubPoints - pickCost;
              // console.log(
              //   "Total is ",
              //   clubPoints,
              //   "remainder",
              //   pointsRemainder
              // );
              continue;
            }

            // Only push picks that should remain
            updatedOrder.push(futurePickTeam);
          }

          //          console.log("updatedOrderBeforeNewPicks", updatedOrder);

          // Allocate new picks
          const newPick = draftValues.find(
            (pick) => pick[1] <= pointsRemainder
          );

          if (newPick) {
            const newPickNum = newPick[0];

            console.log("Allocating new pick number", newPickNum);

            updatedOrder.splice(
              newPickNum - 1,
              0,
              player.preselectionTeam.abbr
            );
          } else {
            console.log("Cant find a new pick, allocating at end");

            updatedOrder.push(player.preselectionTeam.abbr);
          }

          //          console.log("Final updatedOrder", updatedOrder);

          currentOrder = updatedOrder;

          console.log(picksToRemove);
        } else {
          // console.log(`${player.name} is not preselection`);
        }
      }

      // Finished
      set({ orderByTeamId: currentOrder });
    },

    teams: null,
    setTeams: (teams) => {
      set({ teams: teams });
    },

    initError: false,
    setInitError: (value) => set({ initError: value }),

    user: null,
    isUserLoading: true,
    setUser: (user) => set({ user: user }),

    selectedTab: "first",
    setSelectedTab: (tab) => set((state) => ({ selectedTab: tab })),

    isShareOpen: false,
    setIsShareOpen: (isOpen) => set((state) => ({ isShareOpen: isOpen })),

    isRegisterOpen: false,
    setIsRegisterOpen: (isOpen) => set((state) => ({ isRegisterOpen: isOpen })),

    isPreselectionOpen: false,
    setIsPreselectionOpen: (isOpen) =>
      set((state) => ({ isPreselectionOpen: isOpen })),
    preselectionPick: null,
    setPreselectionPick: (pick) => set((state) => ({ preselectionPick: pick })),

    isHelpOpen: false,
    setIsHelpOpen: (isOpen) => set((state) => ({ isHelpOpen: isOpen })),

    showShareDiv: false,
    setShowShareDiv: (show) => set((state) => ({ showShareDiv: show })),

    shareMode: null,
    setShareMode: (mode) => set((state) => ({ shareMode: mode })),

    shareJpegData: null,
    setShareJpegData: (data) => set((state) => ({ shareJpegData: data })),

    shareGroup: 1,
    setShareGroup: (group) => set((state) => ({ shareGroup: group })),

    setupLevel: () => {
      get().fetchUser();
    },
  }),
  Object.is
);

export default useGameStore;
