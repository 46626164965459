import usePersistentStore from "../../lib/store/PersistentStore";
import React from "react";
import { trackEvent } from "../../lib/utils/analytics";

export function ResetButton() {
  const [resetPicks] = usePersistentStore((state) => [state.resetPicks]);

  function handleClick() {
    resetPicks();

    trackEvent("reset_board");
  }

  return (
    <button
      onClick={handleClick}
      type="button"
      className="inline-flex items-center rounded-lg bg-sky-800 px-5 py-2.5 text-center text-md font-medium text-white hover:bg-sky-900 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    >
      <IconUndo className="-ml-1 mr-2 h-5 w-5 text-white" />
      Reset Board
    </button>
  );
}

function IconUndo(props) {
  return (
    <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
      <path
        fill="currentColor"
        d="M5.34 4.468h2v2.557a7 7 0 11-1.037 10.011l1.619-1.185a5 5 0 10.826-7.384h2.591v2h-6v-6z"
      />
    </svg>
  );
}
