import React from "react";
import useGameStore from "../lib/store/GameStore";
import { BoardRow } from "./BoardRow";
import { ResetButton } from "./buttons/ResetButton";
import { ShareButton } from "./buttons/ShareButton";
import { DraftScore } from "./DraftScore";
import { LockedMessage } from "./LockedMessage";

export default function () {
  const [lockState, orderByTeamId, selectedTab] = useGameStore((state) => [
    state.lockState,
    state.orderByTeamId,
    state.selectedTab,
  ]);

  return (
    orderByTeamId && (
      <>
        <div className={selectedTab === "first" ? "" : "hidden"}>
          {lockState === "LOCKED" && <LockedMessage />}
          {lockState === "COMPLETE" && <DraftScore group={1} />}
          <div className="mx-auto mb-6 grid max-w-md gap-x-4 gap-y-2 rounded-md bg-slate-200 p-2 md:max-w-screen-md md:grid-cols-2 md:bg-transparent md:p-0">
            <div
              id="leaderboard-inner"
              className="mx-auto w-full max-w-md space-y-2 rounded-md bg-slate-200 md:p-2"
            >
              <PickRows start={1} num={8} lockState={lockState} />
            </div>
            <div
              id="leaderboard-inner"
              className="mx-auto w-full max-w-md space-y-2 rounded-md bg-slate-200 md:p-2"
            >
              <PickRows start={9} num={8} lockState={lockState} />
            </div>
          </div>
          <div className="mb-8 flex items-center justify-center space-x-4 text-center">
            {lockState === "PRE" ? (
              <>
                <ResetButton />
                <ShareButton group={1} />
              </>
            ) : (
              <ShareButton group={1} />
            )}
          </div>
        </div>
        <div className={selectedTab === "second" ? "" : "hidden"}>
          {lockState === "LOCKED" && <LockedMessage />}
          {lockState === "COMPLETE" && <DraftScore group={2} />}
          <div className="mx-auto mb-6 grid max-w-md gap-x-4 gap-y-2 rounded-md bg-slate-200 p-2 md:max-w-screen-md md:grid-cols-2 md:bg-transparent md:p-0">
            <div
              id="leaderboard-inner"
              className="mx-auto w-full max-w-md space-y-2 rounded-md bg-slate-200 md:p-2"
            >
              <PickRows start={17} num={8} lockState={lockState} />
            </div>
            <div
              id="leaderboard-inner"
              className="mx-auto w-full max-w-md space-y-2 rounded-md bg-slate-200 md:p-2"
            >
              <PickRows start={25} num={8} lockState={lockState} />
            </div>
          </div>
          <div className="mb-8 flex items-center justify-center space-x-4 text-center">
            {lockState === "PRE" ? (
              <>
                <ResetButton />
                <ShareButton group={2} />
              </>
            ) : (
              <ShareButton group={2} />
            )}
          </div>
        </div>
        <div className={selectedTab === "third" ? "" : "hidden"}>
          {lockState === "LOCKED" && <LockedMessage />}
          {lockState === "COMPLETE" && <DraftScore group={3} />}
          <div className="mx-auto mb-6 grid max-w-md gap-x-4 gap-y-2 rounded-md bg-slate-200 p-2 md:max-w-screen-md md:grid-cols-2 md:bg-transparent md:p-0">
            <div
              id="leaderboard-inner"
              className="mx-auto w-full max-w-md space-y-2 rounded-md bg-slate-200 md:p-2"
            >
              <PickRows start={33} num={8} lockState={lockState} />
            </div>
            <div
              id="leaderboard-inner"
              className="mx-auto w-full max-w-md space-y-2 rounded-md bg-slate-200 md:p-2"
            >
              <PickRows start={41} num={8} lockState={lockState} />
            </div>
          </div>
          <div className="mb-8 flex items-center justify-center space-x-4 text-center">
            {lockState === "PRE" ? (
              <>
                <ResetButton />
                <ShareButton group={3} />
              </>
            ) : (
              <ShareButton group={3} />
            )}
          </div>
        </div>
      </>
    )
  );
}

export function PickRows({ order, start, num, lockState }) {
  const picks = [];
  for (let pickNum = start; pickNum < start + num; pickNum++) {
    picks.push(
      <BoardRow key={pickNum} overallPick={pickNum} lockState={lockState} />
    );
  }

  return picks;
}
