import React, { useEffect } from "react";
import Board from "../components/Board";
import useGameStore from "../lib/store/GameStore";
import { ShareDiv } from "../components/ShareDiv";
import PreselectionDialog from "../components/modals/PreselectionDialog";
import ShareDialog from "../components/modals/ShareDialog";
import { isDesktop } from "react-device-detect";
import { canShareImage } from "../../global/lib/utils/sharePermissions";
import usePersistentStore from "../lib/store/PersistentStore";
import { trackEvent } from "../lib/utils/analytics";
import { postSubmission } from "../lib/utils/submit";

export default function (props) {
  //console.log(props);
  const [
    setPlayers,
    setLockState,
    setPickResults,
    setInitialOrderByTeamId,
    setOrderByTeamId,
    generateOrderByTeamId,
    setTeams,
    setShareMode,
    selectedTab,
    setSelectedTab,
  ] = useGameStore((state) => [
    state.setPlayers,
    state.setLockState,
    state.setPickResults,
    state.setInitialOrderByTeamId,
    state.setOrderByTeamId,
    state.generateOrderByTeamId,
    state.setTeams,
    state.setShareMode,
    state.selectedTab,
    state.setSelectedTab,
  ]);

  const [persistedOrder] = usePersistentStore((state) => [state.order]);

  async function init() {
    console.log(props);
    setSelectedTab(props.tab);
    setLockState(props.state);
    setTeams(props.teams);
    setPlayers(props.players);
    await setInitialOrderByTeamId(props.order.map((pick) => pick.team.abbr));
    await setOrderByTeamId(props.order.map((pick) => pick.team.abbr));
    generateOrderByTeamId();
    setPickResults(props.order);

    setTimeout(() => {
      postSubmission();
    }, 2000);
  }

  // Set up game
  useEffect(() => {
    // console.log("Init");
    init();
  }, []);

  async function checkShareSupport() {
    if (isDesktop) {
      setShareMode("download");
    } else {
      const result = await canShareImage();
      setShareMode(result ? "share" : "download");
    }
  }

  useEffect(() => {
    checkShareSupport();
  }, []);

  function onTabClick(tab) {
    const state = { tab: tab };
    const url = `${state.tab}`;
    window.history.pushState(state, "", url);

    setSelectedTab(tab);

    trackEvent(`tab_${tab}`);
  }

  return (
    <>
      <div className="mx-auto mb-4 flex w-min items-center justify-center space-x-1 whitespace-nowrap rounded-full bg-sky-900 p-1 text-center text-md font-medium">
        <button
          onClick={() => onTabClick("first")}
          className={`w-1/3 rounded-full px-4 py-1 ${
            selectedTab === "first"
              ? "bg-white/20 text-white"
              : "text-white/70 hover:bg-white/10 hover:text-white/90"
          } `}
        >
          Picks 1-16
        </button>
        <button
          onClick={() => onTabClick("second")}
          className={`w-1/3 rounded-full px-4 py-1 ${
            selectedTab === "second"
              ? "bg-white/20 text-white"
              : "text-white/70 hover:bg-white/10 hover:text-white/90"
          } `}
        >
          Picks 17-32
        </button>
        <button
          onClick={() => onTabClick("third")}
          className={`w-1/3 rounded-full px-4 py-1 ${
            selectedTab === "third"
              ? "bg-white/20 text-white"
              : "text-white/70 hover:bg-white/10 hover:text-white/90"
          } `}
        >
          Picks 33-48
        </button>
        {/*<a*/}
        {/*  onClick={() => onTabClick("consensus")}*/}
        {/*  href="consensus"*/}
        {/*  className={`w-1/3 rounded-full px-4 py-1 ${*/}
        {/*    selectedTab === "consensus"*/}
        {/*      ? "bg-white/20 text-white"*/}
        {/*      : "text-white/70 hover:bg-white/10 hover:text-white/90"*/}
        {/*  } `}*/}
        {/*>*/}
        {/*  Consensus*/}
        {/*</a>*/}
      </div>
      <Board {...props} />
      {/*<HowToPlayDialog />*/}
      <PreselectionDialog />
      <ShareDialog />
      <ShareDiv />
    </>
  );
}
