import React from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "../../../global/components/BaseDialog";
import usePersistentStore from "../../lib/store/PersistentStore";
import { getPlayerById, getTeamByAbbr } from "../../lib/utils/helpers";

function PreselectionDialog({ modalTitle = "How to Play Gridley" }) {
  const [isOpen, orderByTeamId, setIsOpen, preselectionTeam] = useGameStore(
    (state) => [
      state.isPreselectionOpen,
      state.orderByTeamId,
      state.setIsPreselectionOpen,
      state.preselectionPick,
    ]
  );

  const [picks, currentPickNumber] = usePersistentStore((state) => [
    state.picks,
    state.currentPick,
  ]);

  const selectedPlayerId = picks[currentPickNumber - 1];
  const selectedPlayer = getPlayerById(selectedPlayerId);
  const bidTeam = getTeamByAbbr(preselectionTeam);
  const clockTeamAbbr = orderByTeamId
    ? orderByTeamId[currentPickNumber - 1]
    : "WC";
  const onTheClockTeam = getTeamByAbbr(clockTeamAbbr);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle=""
        modalBody={
          <ModalBody
            bidTeam={bidTeam}
            player={selectedPlayer}
            onTheClockTeam={onTheClockTeam}
          />
        }
        size="lg"
      />
    </>
  );
}

function ModalBody({ bidTeam, player, onTheClockTeam }) {
  return (
    <>
      <div className="text-center text-black">
        <div className="mb-4 flex items-center justify-center">
          <img
            className="mr-4 h-12 w-12"
            src={`/build/images/sport/teams/logos/512/${bidTeam.id}.png`}
            alt={`${bidTeam.name} colours`}
          />
        </div>
        <h3 className="mb-4 text-2xl font-bold dark:text-gray-400">
          Bid Matched!
        </h3>
        <p className="-mx-1 mb-8 text-black dark:text-gray-400 sm:px-4">
          <span className="font-bold">{player.name}</span> is a {bidTeam.name}{" "}
          <span className="font-bold">
            {["GC", "SY"].includes(bidTeam.abbr)
              ? "Academy Player"
              : "Father-Son nomination"}
          </span>{" "}
          and they have decided to match.
        </p>
        <p className="mb-8 text-black dark:text-gray-400">
          {onTheClockTeam.name} are back on the clock.
        </p>
      </div>
    </>
  );
}

export default PreselectionDialog;
