import React from "react";

export function LockedMessage() {
  return (
    <div className="mb-8 mt-8">
      <div className="text-center text-lg font-semibold uppercase text-white dark:text-gray-300">
        Draft in progress
      </div>
      <div className="text-center text-md font-semibold text-white/70 dark:text-gray-300">
        Check back tomorrow for your score
      </div>
    </div>
  );
}
