// controllers/trade_settings_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tradePeriod", "tradeVotes"];

  connect() {
    // Get all radio inputs
    const radioInputs = this.element.querySelectorAll(
      'input[name="trade_settings_form[tradeReview]"]',
    );

    // Add change listeners to all radio inputs
    radioInputs.forEach((input) => {
      input.addEventListener("change", this.handleTradeReviewChange.bind(this));
    });

    // Initialize view based on current selection
    const selectedInput = this.element.querySelector(
      'input[name="trade_settings_form[tradeReview]"]:checked',
    );
    if (selectedInput) {
      this.updateVisibility(selectedInput.value);
    }
  }

  handleTradeReviewChange(event) {
    this.updateVisibility(event.target.value);
  }

  updateVisibility(value) {
    // Convert string value to number for comparison
    const selectedValue = parseInt(value);

    // Show/hide trade period for Commissioner Decision (1) or Coach Votes (2)
    if (this.hasTradePeriodTarget) {
      this.tradePeriodTarget.classList.toggle("hidden", selectedValue === 0);
    }

    // Show/hide trade votes only for Coach Votes (2)
    if (this.hasTradeVotesTarget) {
      this.tradeVotesTarget.classList.toggle("hidden", selectedValue !== 2);
    }
  }
}
