import { calcGrade, calcScore } from "../lib/utils/helpers";
import React from "react";

export function DraftScore({ group }) {
  const score = calcScore(group);
  const grade = calcGrade(score, group);
  const first = (group - 1) * 16 + 1;
  const last = group * 16;

  return (
    <div className="mb-4 mt-8">
      <div className="mb-2 text-center text-lg font-semibold uppercase text-white/80 dark:text-gray-300">
        Picks {first}-{last} score
      </div>
      <div className="relative mb-2 px-8 text-center font-anton text-6xl leading-none text-white sm:text-6xl">
        <div className="countup-wrap">
          <span>{score}</span>
        </div>
      </div>
      <div className="mb-8 text-center text-md font-thin uppercase text-white/80 dark:text-gray-300">
        Draft Grade: <span className="font-bold text-white">{grade}</span>
      </div>
      {/*<div className="mb-8 mt-4 text-center text-md font-bold text-white/80 dark:text-gray-300">*/}
      {/*  {score > 0 && "Thanks for playing!"}*/}
      {/*</div>*/}
    </div>
  );
}
