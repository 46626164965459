import React, { useEffect } from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "../../../global/components/BaseDialog";
import { htmlToJpegDataUrl } from "../../../global/lib/utils/htmlToJpegDataUrl";
import { shareImage } from "../../../global/lib/utils/shareImage";
import { Spinner } from "../../../global/components/icons/Spinner";
import { download } from "../../../global/lib/utils/download";
import { trackEvent } from "../../lib/utils/analytics";

function ShareDialog({ modalTitle = "How to Play Gridley" }) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isShareOpen,
    state.setIsShareOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle=""
        modalBody={<ModalBody />}
        size="lg"
        showClose={true}
      />
    </>
  );
}

function ModalBody({ pick, currentPick }) {
  const [setShowShareDiv, jpegData, setJpegData, shareMode, shareGroup] =
    useGameStore((state) => [
      state.setShowShareDiv,
      state.shareJpegData,
      state.setShareJpegData,
      state.shareMode,
      state.shareGroup,
    ]);

  async function shareJpeg() {
    if (shareMode === "share") {
      try {
        trackEvent("share_image");
        await shareImage(jpegData, "DraftSummary.jpg");
      } catch (err) {
        console.error(err);
      }
    } else {
      trackEvent("download_image");
      download(jpegData);
    }
  }

  useEffect(() => {
    async function generateShareImage() {
      try {
        await setShowShareDiv(true);

        const jpegData = await htmlToJpegDataUrl();
        setJpegData(jpegData);
      } catch (err) {
        console.error(err);
      }
      setShowShareDiv(false);
    }

    if (!jpegData) {
      generateShareImage();
    }
  }, []);

  return (
    <>
      <div className="text-center text-black">
        <h3 className="mb-6 text-2xl font-bold dark:text-gray-400">
          {shareGroup === 1
            ? "Top 16"
            : shareGroup === 2
            ? "Picks 17-32"
            : "Picks 33-48"}{" "}
          Complete
        </h3>

        <div className="mb-6 flex h-64 items-center justify-center">
          {jpegData ? (
            <a href={jpegData} target="_blank">
              <img
                className="h-64"
                src={jpegData}
                alt={`My phantom draft share image`}
              />
            </a>
          ) : (
            <Spinner className="inline h-8 w-8 animate-spin fill-lime-600 text-gray-200 dark:text-gray-600" />
          )}
        </div>
        <p className="mb-6 font-normal text-black dark:text-gray-400 md:mx-0 md:px-8">
          Nice work! Share with your friends, then tune in on draft night to see
          how close you get.
        </p>
        <p className="-mx-1.5 mb-6 text-black dark:text-gray-400">
          {shareMode && (
            <>
              <button
                disabled={!jpegData}
                onClick={shareJpeg}
                className="mx-auto flex items-center rounded-lg bg-lime-600 px-6 py-3 text-center text-md font-medium text-white hover:bg-lime-700 disabled:opacity-50 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {shareMode === "share" ? "Share" : "Download"} Image
              </button>
              {/*<a*/}
              {/*  className="mx-auto -mb-2 mt-2 flex w-min items-center whitespace-nowrap rounded-lg px-6 py-2 text-center text-md text-gray-700 hover:text-gray-900 hover:underline"*/}
              {/*  href="?tab=second"*/}
              {/*>*/}
              {/*  Take me to picks 17-32*/}
              {/*</a>*/}
            </>
          )}
        </p>
      </div>
    </>
  );
}

export default ShareDialog;
