import React, { useState } from "react";
import useGameStore from "../../lib/store/GameStore";
import { Combobox } from "@headlessui/react";
import Dialog from "@mui/material/Dialog";
import { SearchRow } from "../SearchRow";
import { ModalCloseButton } from "../../../global/components/BaseDialog";
import { IconMagnifyingGlass } from "../../../global/components/icons/IconMagnifyingGlass";
import usePersistentStore from "../../lib/store/PersistentStore";
import { getTeamByAbbr } from "../../lib/utils/helpers";

function SearchDialog({ overallPick, isOpen, closeModal, selectedPlayer }) {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        transitionDuration={300}
        componentsProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.4)" } },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="m-4 sm:m-6 md:m-20"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            margin: "0px",
            display: "block",
          },
        }}
        PaperProps={{
          sx: { margin: "0 auto", borderRadius: "0.75rem", maxWidth: "36rem" },
        }}
      >
        <ModalBody overallPick={overallPick} closeModal={closeModal} />{" "}
      </Dialog>
    </>
  );
}

function ModalBody({ overallPick, closeModal }) {
  const [players, orderByTeamId] = useGameStore((state) => [
    state.players,
    state.orderByTeamId,
  ]);
  const [picks] = usePersistentStore((state) => [state.picks]);
  const takenIds = Object.values(picks);
  const selectedPlayerId = picks[overallPick];

  const teamAbbr = orderByTeamId[overallPick - 1];
  const team = getTeamByAbbr(teamAbbr);

  const [hideSelected, setHideSelected] = useState(true);
  function toggleSelected() {
    setHideSelected(!hideSelected);
  }

  const [query, setQuery] = useState("");
  const filteredHidden = hideSelected
    ? players.filter((player) => {
        return player.id === selectedPlayerId || !takenIds.includes(player.id);
      })
    : players;
  const filteredPeople =
    query.length < 1
      ? filteredHidden
      : players
          .filter((player) => {
            return (
              player.name.toLowerCase().includes(query.toLowerCase()) &&
              !takenIds.includes(player.id)
            );
          })
          .slice(0, 20);

  function selectPlayer(player) {
    console.log("Need to optimise this", player.name);
  }

  return (
    <div className="">
      <div
        id="scroll-dialog-title"
        className="sticky top-0 z-50 flex items-center justify-start border-b border-b-gray-200 bg-white p-4 text-center text-lg font-bold leading-6 text-sky-900"
      >
        <ModalCloseButton clickCallback={closeModal} />

        <span className="mr-2 inline-flex items-center justify-center">
          <img
            className="mr-2 h-5 w-5"
            src={`/build/images/sport/teams/logos/512/${team.id}.png`}
            alt={`${team.name} colours`}
          />{" "}
          {team.name} select...
        </span>
      </div>
      <Combobox onChange={selectPlayer}>
        <div className="relative">
          {IconMagnifyingGlass}
          <Combobox.Input
            onChange={(event) => setQuery(event.target.value)}
            // displayValue={(person) => person.name}
            className="h-12 w-full border-0 bg-transparent pl-11 pr-9 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-200 sm:text-md"
            placeholder={`Search...`}
            autoComplete="off"
            autoCorrect="off"
          />
        </div>

        {/*<div className="sticky top-[3rem] z-40 border-y border-gray-200 bg-white backdrop-blur-md dark:border-gray-700 dark:bg-gray-800/70">*/}
        {/*  <div className="mx-auto overflow-auto whitespace-nowrap scrollbar-none">*/}
        {/*    <ul className="mt-0 flex items-center space-x-2 px-2 py-2 text-xs font-medium">*/}
        {/*      <li className="inline-flex items-center">*/}
        {/*        <ToggleChip selected={!hideSelected} onClick={toggleSelected}>*/}
        {/*          Show drafted players*/}
        {/*        </ToggleChip>*/}
        {/*      </li>*/}
        {/*      <li className="inline-flex">*/}
        {/*        <button className="rounded-full border border-gray-500 px-3 py-1.5 text-gray-700 transition-colors hover:bg-gray-200 hover:text-black dark:text-gray-400 dark:hover:bg-primary-800 dark:hover:text-white">*/}
        {/*          MID*/}
        {/*        </button>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {filteredPeople.length > 0 && (
          <Combobox.Options
            static={true}
            className={`z-20 scroll-py-2 space-y-5 overflow-y-auto border-0 border-t border-gray-200 px-4 py-5 text-md text-gray-800 dark:bg-gray-700`}
          >
            {filteredPeople.map((player) => (
              /* Use the `active` state to conditionally style the active option. */
              /* Use the `selected` state to conditionally style the selected option. */
              <Combobox.Option key={player.id} value={player}>
                {({ active, selected }) => (
                  <SearchRow
                    player={player}
                    overallPick={overallPick}
                    closeModal={closeModal}
                  />
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
}

export default SearchDialog;
