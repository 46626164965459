import useGameStore from "../lib/store/GameStore";
import usePersistentStore from "../lib/store/PersistentStore";
import React, { useState } from "react";
import SearchDialog from "./modals/SearchDialog";
import { hasHeadshot } from "../lib/utils/constants";
import {
  getOverallPickForPlayerId,
  getPlayerById,
  getTeamByAbbr,
} from "../lib/utils/helpers";

export function BoardRow({ pick, overallPick, lockState }) {
  const [orderByTeamId, setIsHelpOpen] = useGameStore((state) => [
    state.orderByTeamId,
    state.setIsHelpOpen,
  ]);

  const [picks, currentPick] = usePersistentStore((state) => [
    state.picks,
    state.currentPick,
  ]);
  const onTheClock = lockState === "PRE" && overallPick === currentPick;
  const selectedPlayerId = picks[overallPick];
  const teamAbbr = orderByTeamId[overallPick - 1];
  const team = getTeamByAbbr(teamAbbr);

  const selectedPlayer = getPlayerById(selectedPlayerId);
  const selectedAt = getOverallPickForPlayerId(selectedPlayerId);
  const picksOff = Math.abs(overallPick - selectedAt);
  const pts =
    picksOff === 0
      ? 100
      : picksOff === 1
        ? 50
        : picksOff === 2
          ? 25
          : picksOff === 3
            ? 10
            : 0;

  function handleClick() {
    console.log("openhelp");
    setIsHelpOpen(true);
  }

  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    if (lockState === "PRE" && overallPick <= currentPick) {
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
  }

  const ContainerTag = lockState === "PRE" ? "button" : "div";

  return (
    <>
      <ContainerTag
        onClick={openModal}
        disabled={!onTheClock}
        className={`flex w-full items-center transition-transform ${
          onTheClock ? "hover:scale-[1.01] active:scale-[.98]" : ""
        }`}
      >
        <div
          className={`flex w-full flex-col ${
            onTheClock ? "bg-lime-500" : "bg-white"
          }`}
        >
          <div
            className={`flex items-center ${
              lockState === "COMPLETE" ? "py-2" : "py-1"
            }`}
          >
            <div className="flex-co relative flex h-16">
              <div
                id="rank"
                className={`relative flex w-14 min-w-[1.75rem] flex-none items-center justify-center font-oswald text-3xl font-bold tracking-tight ${
                  onTheClock ? "text-white" : "text-sky-700"
                } antialiased`}
              >
                {overallPick}
                {/*<div className="absolute bottom-0 font-oswald text-xs text-lime-600">*/}
                {/*  +10pts*/}
                {/*</div>*/}
              </div>
            </div>
            <div id="name" className="flex flex-none items-center">
              <img
                className="mr-4 h-12 w-12"
                src={`/build/images/sport/teams/logos/512/${team.id}.png`}
                alt={`${team.name} colours`}
              />
              {selectedPlayer ? (
                <div className="flex flex-col">
                  <div className="text-ellipsis text-left text-xl font-medium uppercase leading-none text-sky-950 antialiased">
                    {selectedPlayer.firstName}
                  </div>
                  <div className="text-ellipsis text-left font-anton text-3xl uppercase leading-none text-sky-950 antialiased">
                    {selectedPlayer.lastName}
                  </div>
                </div>
              ) : onTheClock ? (
                <div className="flex items-center text-left text-lg font-semibold uppercase text-white">
                  <span>On the clock</span>
                  <span className="relative ml-2 flex h-3 w-3">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-white opacity-75"></span>
                    <span className="relative inline-flex h-3 w-3 rounded-full bg-lime-300"></span>
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="grow"></div>
            <div className="h-18 relative -ml-60 flex w-28 flex-none items-center justify-center">
              <div
                className={`absolute ${
                  lockState === "COMPLETE" ? "h-[5.5rem]" : "h-20"
                }  w-28`}
              >
                {selectedPlayer && lockState === "COMPLETE" && (
                  <div
                    className={`absolute bottom-1 right-0 z-20 flex items-center justify-center rounded-tl-md px-2 py-0.5 font-oswald text-xxs font-medium uppercase leading-none ${
                      picksOff === 0
                        ? "bg-lime-600/90 text-white/70"
                        : picksOff <= 3
                          ? "bg-yellow-500/90 text-white/70"
                          : "bg-red-900/90 text-white/70"
                    }`}
                  >
                    {selectedAt == null ? (
                      <span className="mr-2">UNDRAFTED</span>
                    ) : (
                      <span className="mr-2">Pick {selectedAt}</span>
                    )}{" "}
                    <span
                      className={`text-md leading-none ${
                        picksOff <= 3 ? "text-white" : "text-white/90"
                      }`}
                    >
                      +{pts}pts
                    </span>
                  </div>
                )}
                <div className="absolute -top-1 flex h-full w-full items-start justify-center overflow-hidden">
                  {selectedPlayer &&
                    (hasHeadshot.includes(selectedPlayer.id) ? (
                      <img
                        src={`/build/images/sport/juniorplayers/${selectedPlayer.id}.png`}
                        alt={`${selectedPlayer.name} headshot`}
                        className="absolute -right-5 -top-4"
                      />
                    ) : (
                      <img
                        src={`/build/images/sport/juniorplayers/silhouette.png`}
                        alt={`${selectedPlayer.name} headshot`}
                        className="absolute -right-5 -top-4"
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerTag>
      <SearchDialog
        overallPick={overallPick}
        isOpen={isOpen}
        closeModal={closeModal}
        selectedPlayer={selectedPlayer}
      />
    </>
  );
}
