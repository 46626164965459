import usePersistentStore from "../store/PersistentStore";
import { calcScore } from "./helpers";

export function postSubmission() {
  const url = `api/submit`;
  const data = {
    browserId: usePersistentStore.getState().browserId,
    picks: usePersistentStore.getState().picks,
    score1: calcScore(1),
    score2: calcScore(2),
    score3: calcScore(3),
  };
  console.log(data);
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    // credentials: "include",
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Submission response was not ok.");
      }

      return response.json();
    })
    .then((data) => {
      // Handle the response data here (if needed)
      console.log("Response:", data);
    });
  // .catch((error) => {
  //   // Handle any error that occurred during the fetch
  //   console.error("Error:", error);
  // });
}
